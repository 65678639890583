<template>
  <div class="container-fluid container-xl signup">
    <div class="header">
      <img src="../../assets/logo-transparent-cropped.png" alt="VTG LOGO" />
    </div>
    <div class="content">
      <img
        src="../../assets/img/svg/forgot-password.svg"
        alt="forgot password"
      />
      <h3 class="text-center mt-3">Forgot Password</h3>
      <p class="text-center mb-3 mb-sm-4">
        Confirm your email and we'll send the instructions
      </p>
      <b-form-group id="input-group-1" label="Email:" label-for="input-1">
        <div class="inputField">
          <b-icon icon="envelope"></b-icon>
          <b-form-input
            id="input-1"
            type="email"
            v-model="email"
            required
          ></b-form-input>
        </div>
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <span class="text-muted">OR</span>
      </div>
      <b-form-group id="input-group-2" label="Mobile No:" label-for="input-2">
        <div class="inputField">
          <b-icon icon="telephone"></b-icon>
          <b-form-input id="input-2" type="tel" v-model="mobile"></b-form-input>
        </div>
      </b-form-group>
      <b-button
        variant="primary"
        class="nextBtn mt-3 mb-3"
        @click="submit"
      >
        Send
      </b-button>
    </div>

    <div class="footer">
      <p>Copyright JabComp Ⓒ {{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      mobile: "",
    };
  },
  methods: {
    submit() {
      this.$router.push('/verify')
    },
  },
};
</script>

<style lang="scss" scoped>
.signup {
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  .header {
    width: 100%;
    height: 100px;
    background: #005eaa33;
    text-align: center;
    border-radius: 0 0 70px 70px;

    > img {
      height: 100%;
      max-width: 90%;
      object-fit: contain;
    }
  }

  .content {
    height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:20px;

    > img {
      height: 10vmax;
      max-height: 350px;
      min-height: 150px;
    }
    .inputField {
      position: relative;
      .b-icon {
        position: absolute;
        left: 15px;
        top: 8px;
        font-size: 20px;
      }
    }
    input {
      width: 350px;
      padding: 0 40px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      border-radius: 90px;

      @media (max-width: 400px) {
        width: 250px;
      }
    }

    .nextBtn {
      width: 350px;
      box-shadow: 15px 15px 40px #4343434d;
      border-radius: 90px;

      @media (max-width: 400px) {
        width: 250px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #005eaa33;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px 20px 0 0;

    > p {
      margin: 0;
    }
  }
}
</style>